/// <reference path="../../../../entities/dashboardSSP/Deal.js" />

import { api } from '.';
import { DEALS_RESOURCE, PLATFORM_DEALS_RESOURCE, PLATFORM_RESOURCE, SELLER_RESOURCE } from '@/services/keys';
import ResponseBuilder from '@/model/shared/ResponseBuilder';
import dashboardSSPEntityParser from '@/entities/dashboardSSP/DashboardSSPEntityParser';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';

export const getResources = dealsId => ({
  [DEALS_RESOURCE]: dealsId,
});

/**
 *
 * @param {string} dealId
 * @return {Promise.<{data:Deal}>}
 */
export async function getDealById(dealId) {
  const params = new QueryParamsBuilder();
  const partialUrl = api.createUrl(getResources(dealId));

  params.addInclude(PLATFORM_DEALS_RESOURCE, PLATFORM_RESOURCE, SELLER_RESOURCE);
  const { data } = await api.get(partialUrl, params.buildWithoutPage());

  return new ResponseBuilder(dashboardSSPEntityParser.parse(data), data.meta).build();
}
