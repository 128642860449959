import { api } from '.';
import { SALESFORCE_USERS_RESOURCE } from '@/services/keys';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import ResponseBuilder from '@/model/shared/ResponseBuilder';
import dashboardSSPEntityParser from '@/entities/dashboardSSP/DashboardSSPEntityParser';

export const getResources = userId => ({
  [SALESFORCE_USERS_RESOURCE]: userId,
});

/**
 *
 * @param {QueryParamsBuilder} params
 * @returns {Promise.<{data:Salesforce, meta:import('@/model/shared/ResponseBuilder').EndpointMeta}>}
 */
export async function getSalesforceUserById(userId) {
  const partialUrl = api.createUrl(getResources(userId));
  const params = new QueryParamsBuilder();

  const { data } = await api.get(partialUrl, params.buildWithoutPage());
  return new ResponseBuilder(dashboardSSPEntityParser.parse(data), data.meta).build();
}
