var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "flex flex-col" }, [
    _c("span", {
      staticClass:
        "bg-gray-400 animate-pulse ml-2 sm:ml-8 pl-4 px-8 rounded-t-lg w-10/12 sm:w-2/5 py-4",
    }),
    _c(
      "div",
      {
        staticClass:
          "flex flex-col border border-gray-400 p-8 rounded-lg shadow-lg",
      },
      _vm._l(_vm.rows, function (index) {
        return _c(
          "div",
          {
            key: `loading_form_${index}`,
            staticClass: "flex justify-between w-full",
            class: [index === 1 ? "" : "mt-8"],
          },
          [_vm._m(0, true), _vm._m(1, true)]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "w-full flex flex-col" }, [
      _c("div", {
        staticClass: "w-20 h-4 bg-gray-300 animate-pulse rounded-full",
      }),
      _c("div", {
        staticClass: "w-64 h-10 bg-gray-300 animate-pulse rounded mt-2",
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "w-full flex flex-col" }, [
      _c("div", {
        staticClass: "w-20 h-4 bg-gray-300 animate-pulse rounded-full",
      }),
      _c("div", {
        staticClass: "w-64 h-10 bg-gray-300 animate-pulse rounded mt-2",
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }