<template functional>
  <div class="bg-white flex flex-col p-6 lg:p-12 rounded rounded-lg shadow-md text-left mb-12">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Card',
};
</script>

<style scoped></style>
