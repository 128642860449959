/// <reference path="../../../../entities/dashboardSSP/Salesforce.js" />

import { api } from '.';
import { SALESFORCE_ACCOUNT_RESOURCE } from '@/services/keys';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import ResponseBuilder from '@/model/shared/ResponseBuilder';
import dashboardSSPEntityParser from '@/entities/dashboardSSP/DashboardSSPEntityParser';

/**
 *
 * @param {QueryParamsBuilder} params
 * @returns {Promise.<{data:Salesforce[]}>}
 */
export async function getSalesforceAccounts(params = new QueryParamsBuilder()) {
  const partialUrl = api.createUrl({
    [SALESFORCE_ACCOUNT_RESOURCE]: undefined,
  });
  const { data } = await api.get(partialUrl, params.build());
  return new ResponseBuilder(dashboardSSPEntityParser.parse(data), data.meta).build();
}
