var render = function render(_c, _vm) {
  return _c(
    "div",
    {
      staticClass:
        "bg-white flex flex-col p-6 lg:p-12 rounded rounded-lg shadow-md text-left mb-12",
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }