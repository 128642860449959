import { getDeals } from './getDeals';
import { getDealById } from './getDealById';
import { getDealByNameOrOpportunityId } from './getDealByNameOrOpportunityId';
import { getDealTypeList } from './getDealTypeList';

import { dashboardSSP } from '..';

export const api = dashboardSSP;

export { getDeals, getDealById, getDealByNameOrOpportunityId, getDealTypeList };
