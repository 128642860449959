export default class Column {
  constructor(
    title = '',
    value = null,
    visible = true,
    sort = false,
    rolesAllowed = [],
    cssClass = '',
    sortInverted = false,
    currency = null
  ) {
    this.title = title;
    this.name = title;
    this.value = value || title.toLowerCase();
    this.visible = visible;
    if (sort) this.sort = sort; // XXX: debido a que en el componente se comprueba si tiene valor, no que sea true/false

    /** @readonly @type {string[]} List of roles id can be shown this filter */
    this.rolesAllowed = rolesAllowed;
    /** @readonly @type {string[]} List of roles id can be shown this filter */
    this.breakdowns = [];
    /** @private @type {string} */
    this.class = cssClass;
    this.stickyColumn = false;
    this.align = 'left';
    this.sortInverted = sortInverted;
    this.subtitle = '';
    this.queryKeys = [value];
    this.currency = currency;
  }

  /**
   * Set sort property as true
   * @returns Column
   */
  asSort(inverted = false) {
    this.sort = true;
    this.sortInverted = inverted;
    return this;
  }

  /**
   * Align the column to the right
   * @returns Column
   */
  toRight() {
    this.align = 'right';
    return this;
  }

  /**
   * Set stickyColum property as true
   * @returns Column
   */
  asStickyColumn() {
    this.stickyColumn = true;
    return this;
  }

  /**
   * @param {...string} allowedRoles List of roles id can be shown this filter.
   * @returns Column
   */
  setAllowedRoles(...allowedRoles) {
    this.rolesAllowed = allowedRoles.flatMap(roleId => {
      if (Array.isArray(roleId)) return roleId.flatMap(rol => rol.id || rol);
      return roleId.id || roleId;
    });
    return this;
  }

  /**
   * @param {import('./roles').Role|string} rol Rol id
   * @returns boolean Return true if this rol exit on allowed roles list or if roles list is empty else false
   */
  isRolAllowed(rol) {
    return !this.rolesAllowed.length || this.rolesAllowed.includes(rol.id || rol);
  }

  /**
   * set css class using on header talble
   * @returns Column
   */
  setClass(cssClass) {
    this.class = cssClass;
    return this;
  }

  /**
   * set breakdowns
   * @returns Column
   */
  setBreakdowns(breakdowns = []) {
    this.breakdowns = breakdowns;
    return this;
  }

  /**
   * set subtitle
   * @returns Column
   */
  setSubtitle(subtitle = '') {
    this.subtitle = subtitle;
    return this;
  }

  /**
   * update query keys for endpoints (e.g. from cost to costValue and costCurrency)
   * @param {string[]} values
   */
  setQueryKeys(values) {
    this.queryKeys = values;
    return this;
  }

  visibleTitle() {
    return this.subtitle ? `${this.title} ${this.subtitle}` : this.title;
  }

  /**
   * set the currency of the column
   * @param {String} value
   * @returns Column
   */
  setCurrency(value) {
    this.currency = value;
    return this;
  }

  setVisible(value) {
    this.visible = value;
    return this;
  }
}
