import get from 'lodash/get';
import { getSalesforceUserById } from '@/services/modules/Core/salesforce';

export default {
  data: () => ({
    salesforceUserEmailList: {},
  }),
  methods: {
    async setSellers(sellerKey = 'seller') {
      const promises = [];
      const salesforceUserEmailList = this.salesforceUserEmailList;

      this.items.forEach(async item => {
        const seller = get(item, sellerKey, null);
        if (seller && !salesforceUserEmailList[seller.id]) {
          salesforceUserEmailList[seller.id] = 'loading...';
          promises.push(
            new Promise(async resolve => {
              try {
                const { data } = await getSalesforceUserById(seller.id);
                salesforceUserEmailList[seller.id] = data?.email || '';
                resolve();
              } catch (e) {
                salesforceUserEmailList[seller.id] = '';
                resolve();
              }
            })
          );
        }
      });
      await Promise.all(promises);

      // Force vue reactiv
      this.salesforceUserEmailList = null;
      this.salesforceUserEmailList = salesforceUserEmailList;
    },
  },
};
