import dashboardSSPEntityParser from '@/entities/dashboardSSP/DashboardSSPEntityParser';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import ResponseBuilder from '@/model/shared/ResponseBuilder';
import { DEALS_TYPES_RESOURCE } from '@/services/keys';
import { api } from '.';

export const getResources = () => ({
  [DEALS_TYPES_RESOURCE]: undefined,
});

/**
 *
 * @param {QueryParamsBuilder} params
 * @return {Promise.<{data:DealType[],meta:import('@/model/shared/ResponseBuilder').EndpointMeta}>}
 */
export async function getDealTypeList(params = new QueryParamsBuilder()) {
  const partialUrl = api.createUrl(getResources());
  const query = params.removeFilter('name');
  params.addFilter('deal.type', query);
  const { data } = await api.get(partialUrl, params.build());
  return new ResponseBuilder(dashboardSSPEntityParser.parse(data), data.meta).build();
}
