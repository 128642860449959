/// <reference path="../../../../entities/dashboardSSP/Deal.js" />

import { api } from '.';
import { DEALS_RESOURCE } from '@/services/keys';
import ResponseBuilder from '@/model/shared/ResponseBuilder';
import dashboardSSPEntityParser from '@/entities/dashboardSSP/DashboardSSPEntityParser';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';

export const getResources = () => ({
  [DEALS_RESOURCE]: undefined,
});

/**
 *
 * @param {QueryParamsBuilder} params
 * @return {Promise.<{data:Deal[],meta:import('@/model/shared/ResponseBuilder').EndpointMeta}>}
 */
export async function getDeals(params = new QueryParamsBuilder()) {
  const partialUrl = api.createUrl(getResources());
  params.addInclude('sellers');

  // Back doesn't need clientId filter
  params.addFilter('client.id', undefined);

  const { data } = await api.get(partialUrl, params.build());
  return new ResponseBuilder(dashboardSSPEntityParser.parse(data), data.meta).build();
}
