<template>
  <div class="flex flex-col">
    <span class="bg-gray-400 animate-pulse ml-2 sm:ml-8 pl-4 px-8 rounded-t-lg w-10/12 sm:w-2/5 py-4"> </span>
    <div class="flex flex-col border border-gray-400 p-8 rounded-lg shadow-lg">
      <div
        v-for="index in rows"
        :key="`loading_form_${index}`"
        class="flex justify-between w-full"
        :class="[index === 1 ? '' : 'mt-8']"
      >
        <div class="w-full flex flex-col">
          <div class="w-20 h-4 bg-gray-300 animate-pulse rounded-full"></div>
          <div class="w-64 h-10 bg-gray-300 animate-pulse rounded mt-2"></div>
        </div>
        <div class="w-full flex flex-col">
          <div class="w-20 h-4 bg-gray-300 animate-pulse rounded-full"></div>
          <div class="w-64 h-10 bg-gray-300 animate-pulse rounded mt-2"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'CardFormLoading',
  props: {
    rows: {
      type: Number,
      default: () => 1,
    },
  },
};
</script>

<style></style>
