import { api } from '.';
import { SALESFORCE_ACCOUNT_RESOURCE } from '@/services/keys';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import ResponseBuilder from '@/model/shared/ResponseBuilder';
import dashboardSSPEntityParser from '@/entities/dashboardSSP/DashboardSSPEntityParser';

export const getResources = id => ({
  [SALESFORCE_ACCOUNT_RESOURCE]: id,
});

/**
 *
 * @param {string} accountId
 * @returns {Promise.<{data:Salesforce}>}
 */
export async function getSalesforceAccountById(accountId) {
  const partialUrl = api.createUrl(getResources(accountId));
  const params = new QueryParamsBuilder();

  const { data } = await api.get(partialUrl, params.buildWithoutPage());
  return new ResponseBuilder(dashboardSSPEntityParser.parse(data), data.meta).build();
}
