import { api } from '.';
import { DEALS_TRANSACTION_RESOURCE } from '@/services/keys';
import ResponseBuilder from '@/model/shared/ResponseBuilder';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import dashboardSSPEntityParser from '@/entities/dashboardSSP/DashboardSSPEntityParser';

export const getResources = opportunityId => ({
  [DEALS_TRANSACTION_RESOURCE]: opportunityId,
});

/**
 * @param {string} opportunityId
 * @param {QueryParamsBuilder} params
 * @return {Promise.<{data:Deal[],meta:import('@/model/shared/ResponseBuilder').EndpointMeta}>}
 */
export async function getDealByNameOrOpportunityId(opportunityId = ' ', params = new QueryParamsBuilder()) {
  const partialUrl = api.createUrl(getResources(opportunityId));
  params.addSort('deal.name');
  const { data } = await api.get(partialUrl, params.build());
  return new ResponseBuilder(dashboardSSPEntityParser.parse(data), data.meta).build();
}
